import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { CrmApi } from './utils/hocs/CrmApi';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <CrmApi> <App /> </CrmApi>
        </BrowserRouter>
    </Provider>
);


import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import s from './header.module.scss';
import img_phone from  '../../images/icon_phone.png';
import img_news from '../../images/icon_news.png';
import img_arrow from '../../images/icon_arrow.png';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, setMenu, setModalContacts } from '../../redux/stateSlice';
import { useLang } from '../../utils/hooks/useLang';
import { useLink } from '../../utils/hooks/useLink';
import { useTerms } from '../../utils/hooks/useTerms';

export const Header = ()=>{

    const langs = useLang();
    const getTerms = useTerms();
    const getLink = useLink();

    const lng_menu = useRef();
    const lng_menu_wrapper = useRef();
    const header = useRef();
    const header_arrow = useRef();
    const state = useSelector(store => store.state.header_menu);
    const lang = JSON.parse(localStorage.getItem('lang'));
    const dispatch = useDispatch();
    const menu_items = document.getElementsByClassName('header__menu_item');
    const [language, setLang] = useState(lang.key);
    const navigate = useNavigate();
    
    // Change language
    const changeLanguage = (el) => {
        const key = el.target.getAttribute('ln_key');
        const id = el.target.getAttribute('ln_id');
        setLang(key);
        dispatch(setLanguage({id: id, key: key}));
        localStorage.setItem('lang', JSON.stringify({id: id, key: key}));
    }

    
    // set new menu state
    function setMenuState(el){
        let newState = el.target.getAttribute("id");
        dispatch(setMenu(newState));
        if(newState === "contacts") dispatch(setModalContacts(true));
        if(newState === 'home') navigate("/");
        if(newState === 'news') navigate("news/");
    }

    // Open\Close language menu
    function lng_menuHandler(el){
        if(lng_menu_wrapper.current.offsetHeight){
            lng_menu_wrapper.current.style.height='0px';
            header_arrow.current.style.transform = "rotate(0deg)";
        } 
        else{
            lng_menu_wrapper.current.style.height=`${lng_menu.current.offsetHeight}px`;
            header_arrow.current.style.transform = "rotate(180deg)";
        } 
    }

    useEffect(()=>{
        gsap.to('.header', {opacity: 1, duration: 1, delay: 0.5});
    },[]);

    // set styles menu button
    useEffect(()=>{
        for(let el of menu_items){
            el.classList.remove('menu_active');
        }
        document.getElementById(state).classList.add("menu_active");
    },[state]);

    // Open modal
    function openContacts(){
        dispatch(setModalContacts(true));
    }

    return(
        <header className={`${s.header} header`} ref={header}>
            <div className={s.header__title}>
                <p id="home" className='header__menu_item' onClick={(el)=>setMenuState(el)}>{getLink('home').text}</p>
            </div>
            <div className={s.header__menu}>
                <div className={s.header__menu_link}>
                    <img src={img_phone} alt="icon_phone" />
                    <p id="contacts" onClick={(el)=>setMenuState(el)} className={`${s.header__contacts} header__menu_item`}>{getTerms('hdlc')}</p>
                </div>
                <div className={s.header__menu_link}>
                    <img src={img_news} alt="icon_news" />
                    <p id="news" onClick={(el)=>setMenuState(el)} className={`${s.header__news} header__menu_item`}>{getLink('hdln').text}</p>
                </div>
                <div className={s.header__menu_lang} onClick={lng_menuHandler}>
                    <span>{language}</span>
                    <img src={img_arrow} alt="arrow" ref={header_arrow} />
                    <div className={s.menu__dropdown_wrapper} ref={lng_menu_wrapper}>
                        <div className={s.menu__dropdown} ref={lng_menu}>
                            {
                                langs?
                                langs.map((ln, index) => {
                                    return <span key={index} onClick={changeLanguage} ln_key={ln.key} ln_id={ln.id} >{ln.key}</span>
                                }):null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
import React from 'react';
import s from './product_item.module.scss';

export const ProductItem = (props)=>{

    return(
        <div className={s.product__item}>
            <div className={s.icon__wrapper} style={{
                background: `url(${props.image}) no-repeat center/contain`,
            }}></div>
            <div className={s.item__text}>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </div>
    )
}
import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import Dexie from 'dexie';


export function useLang(){

    const version = useSelector(store => store.state.db_version);

    const db = new Dexie('store');
    db.version(version).stores({
        mfpt: "id"
    });
    
    const lang_query = useLiveQuery(() => db.mfpt.get(2));
    
    if(lang_query){
        return lang_query.lang;
    }
}
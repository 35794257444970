import React, { useEffect } from 'react';
import { setMenu, setPreloader } from '../../redux/stateSlice';
import { Footer } from '../footer/footer';
import { MyButton } from '../mybutton/mybutton';
import { PreFooter } from '../prefooter/prefooter';
import s from './news.module.scss';
import { NewsItem } from './news_item/news_item';
import { useDispatch, useSelector } from 'react-redux';
import { stopScroll } from '../../functions/stopScroll';
import gsap from 'gsap';
import { usePosts } from '../../utils/hooks/usePosts';
import { useTerms } from '../../utils/hooks/useTerms';

export const News = ()=>{

    const dispatch = useDispatch();
    const preloader = useSelector(store => store.state.preloader);
    

    const getPosts = usePosts();
    const posts = getPosts();
    const getTerms = useTerms();
    const morebtn = getTerms('morebtn');

    useEffect(()=>{
        dispatch(setMenu('news'));
        window.scrollTo(2, 0);
        window.addEventListener("wheel", stopScroll, { passive:false })
        let delay = 0;
        preloader? delay = 0 : delay = 4000;
        setTimeout(()=>{
            dispatch(setPreloader(true));
            window.removeEventListener("wheel", stopScroll)
        },delay);
        
        gsap.from('.news', {opacity: 0, duration: 1, delay: 0.6});


    },[]);

    if(!posts) return;
    let result_posts;
    
    const result = posts.map(post =>{
        const post_content = {id: post.id};
        post.template.forEach(tm => {
            if(tm.tm_type === "text") post_content['title'] = tm.content[0]?.pseudo;
            if(tm.tm_type === "file") post_content['title_image'] = tm.content[0]?.id;
        });
        for(let i in post.content){
            if(post.content[i].type === "text"){
                post_content['subtitle'] = post.content[i].value;
                break;
            }
        }
        return post_content;
    });
    result_posts = result;

    const news_view = result_posts.map((el, key) => {
        return <NewsItem image={el.title_image} title={el.title} text={el.subtitle} id={el.id} key={key}/>
    })

    return(
        <section className={`${s.news} news`}>
            <div className={s.news__container}>
                <div className={s.news__content}>
                    {news_view}
                </div>
                <div className={s.button__wrapper}>
                        {result_posts.length > 6 ? <MyButton text={morebtn} type="modal" /> : null}
                </div>
            </div>
            <PreFooter />
            <Footer />
        </section>
    )
}
import React, { useEffect } from 'react';
import s from './mission.module.scss';
import { MyButton } from '../../mybutton/mybutton';
import svg_sprite from '../../../images/svg_sprite.svg';
import { useDispatch } from 'react-redux';
import { setModalContacts } from '../../../redux/stateSlice';
import gsap from 'gsap';
import { useTerms } from '../../../utils/hooks/useTerms';
import { useMedia } from '../../../utils/hooks/useMedia';

export const Mission = ()=>{

    const dispatch = useDispatch();

    function setModalState(){
        dispatch( setModalContacts(true) );
    }

    const getTerms = useTerms();
    const getMedia = useMedia();

    useEffect(()=>{
        gsap.from('.mission', {opacity: 0, duration: 1, delay: 0.7});
    },[]);
   
    return(
        <section className={`${s.mission} mission`}>
            <div className={s.mission__content}>
                <div className={s.mission__lc}>
                    <h1 className='mission_title' data-anim="opacity">{getTerms('mis1')}</h1>
                    <p className='mission_text' data-anim="opacity">{getTerms('mis2')}</p>
                    <div className={`${s.button__wrapper} mission_button`} onClick={setModalState} data-anim="opacity">
                        <MyButton text={getTerms('btm')} type="modal" />
                    </div>
                </div>
                <div className={s.mission__rc}>
                <div className={s.img__wrapper} style={{
                    background: `url(${getMedia(635).link}) no-repeat center/cover`,
                }}></div>
                </div>
                <div className={s.border__wrapper} data-anim="line">
                    <svg className={`${s.main__heart} animation_heart`}>
                        <use xlinkHref={`${svg_sprite}#heart`} ></use>
                    </svg>
                </div>
            </div>
            <div className={s.mission__footer} >
                <h2 data-anim="opacity">{getTerms('mtf')}</h2>
            </div>
        </section>
    )
}
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Header } from "./components/header/header";
import { Main } from "./components/main/main";
import "./index.scss";
import { News } from "./components/news/news";
import { AboutNews } from "./components/about_news/about_news";
import { Contacts } from "./components/contacts/contacts";
import { Animation } from "./components/animation/animation";
import { Preloader } from "./components/preloader/preloader";
import { useTransition, animated } from '@react-spring/web';
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./redux/stateSlice";

function App() {

  const dispatch = useDispatch();
  const loading = useSelector(store => store.state.isLoading);
  const location = useLocation();

  const transitions = useTransition(location, {
    
    enter:{
      opacity: 1,
    },
    leave: {
      opacity: 0,
      transition: ".2s"
    },
    config: {duration: 400},
  });

  useEffect(()=>{
    const ln = JSON.parse(localStorage.getItem('lang'));
    if(ln) dispatch(setLanguage(ln))
  },[]);

  
  return(
    <div className="App">
        {loading? null : 
          (
          <>
          <div className="container">
            <Header />
              {transitions((style, item) => (
              <animated.div style={style}>
                  <Routes location={item}>
                    <Route path="/" element={<Animation><Main /></Animation>} />
                    <Route path="news/" element={<Animation><News /></Animation>} />
                    <Route path="about-news/" element={<Animation><AboutNews /></Animation>} />
                  </Routes>
              </animated.div>
              ))}
          </div>
          <Contacts />
          {/* <Command /> */}
          </>
          )
        }
        <Preloader />
    </div>
  )
}


export default App;

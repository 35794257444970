import React from 'react';
import s from './quote.module.scss';
import heart from "../../../images/heart.webp";
import svg_sprite from "../../../images/svg_sprite.svg";
import { useTerms } from '../../../utils/hooks/useTerms';
import { useMedia } from '../../../utils/hooks/useMedia';

export const Quote = ()=>{

    const getTerms = useTerms();
    const getMedia = useMedia();

    return(
        <section className={s.quote}>
            <div className={s.quote__lc}>
                <div className={s.quote__content}>

                    <div className={`${s.quote__text} quote__text`} data-anim="write">
                        <div className={`${s.quote__heart_container}`} style={{
                            background: `url(${heart}#icon_phone)`,
                            backgroundPosition: "center center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat"
                        }}></div>
                        <div className={`${s.quote__text_container} quote__text_container`}>

                        </div>
                    </div>

                    <p className={`${s.quote__name} quote__name_animation`}>{getTerms('tcn')} <span>{getTerms('tcp')}</span></p>
    
                </div>
            </div>
            <div className={s.quote__rc}>
                <div className={s.quote__img_wrapper} style={{
                    background: `url(${getMedia(642).link}) no-repeat center/contain`,
                }}></div>
            </div>
            <div className={s.border__wrapper} data-anim="line">
                <svg className={s.heart_bottom}>
                     <use xlinkHref={`${svg_sprite}#heart`} ></use>
                </svg>
            </div>
        </section>
    )
}
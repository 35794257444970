import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDBVersionApi, getPagesApi } from '../../redux/apis/mainApi';
import { setDBVersion, setIsLoading, setLanguage } from '../../redux/stateSlice';
import Dexie from 'dexie';
const address = process.env.REACT_APP_API_ADDRESS;




export const CrmApi = ({children})=>{

    const dispatch = useDispatch();
   
    async function getDBVersion(){
        const version = await getDBVersionApi();
        const current_version = await new Dexie("store").open().then((db) => {db.close(); return db.verno}).catch((e)=> 0);
        
        if(!version){
            dispatch(setDBVersion(current_version));
            dispatch(setIsLoading(false));
            return;
        }

        dispatch(setDBVersion(version.data));
        if(current_version < version.data){

            const db = new Dexie('store');
            db.version(version.data).stores({
                mfpt: 'id'
            });
            db.open();
            db.on("ready", () => {
                fetch(`${address}db/get/`)
                .then(response => {return response.json()}).then(response => {
                    db.mfpt.delete(1);
                    db.mfpt.delete(2);
                    db.mfpt.delete(3);
                    db.mfpt.delete(4);
                    db.mfpt.delete(5);
                    db.mfpt.add({id: 1, blocks: response.blocks});
                    db.mfpt.add({id: 2, lang: response.lang});
                    db.mfpt.add({id: 3, posts: response.posts});
                    db.mfpt.add({id: 4, terms: response.terms});
                    db.mfpt.add({id: 5, media: response.media});

                    const language = localStorage.getItem('lang')
                    if(language) dispatch(setLanguage(JSON.parse(language)))
                    else{
                        localStorage.setItem('lang', JSON.stringify(response.lang[0]));
                        dispatch(setLanguage(response.lang[0]))
                    }
                
                    console.log("База Indexed DB обновлена - ", response);
                    db.close();
                    dispatch(setIsLoading(false));
                });
            });
        } else dispatch(setIsLoading(false));
    }

    useEffect(()=>{
        getDBVersion();
    },[]);

    return children;
}
import React, { useEffect } from 'react';
import { NewsList } from '../list/news_list';
import s from './postItem.module.scss';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CardLeft } from '../card_left/card_left';
import { CardRight } from '../card_right/card_right';
import { useMedia } from '../../../utils/hooks/useMedia';
import { useTerms } from '../../../utils/hooks/useTerms';
import { Block } from '../block/block';


export const PostItem = (props)=>{

    const post = props.post;
   
    gsap.registerPlugin(ScrollTrigger);
    const getMedia = useMedia();
    const getTerms = useTerms();
    
    useEffect(()=>{
        
        setTimeout(()=>{
            gsap.utils.toArray('.news-animation').forEach((elem) => {
                gsap.fromTo(
                    elem,
                    {
                        y: 100,
                        opacity: 0,
                    },
                    {
                        scrollTrigger: {
                        trigger: elem,
                        start: '50px bottom',
                        },
                        duration: 2,
                        ease: 'expo.out',
                        y: 0,
                        opacity: 1,
                    }
                    );
            });
        },1000)

    },[]);
   
    const content_view = post.map(el => {
        if(el.type === 'text'){
            return <p className={s.text_block} key={el.id}>{getTerms(el.value)}</p>
        }
        if(el.type === 'image'){
            return <img className={s.image_block} key={el.id} src={getMedia(el.value).link} alt={getMedia(el.value).alt}></img>
        }
        if(el.type === 'block'){
            return <Block text={el.content.text} key={el.id} media={el.content.media} style={el.style}/>
        }
       
    })

    return(
        <div className={s.about__content}>
            {content_view}
                
            </div>
    )
}
import { createSlice } from "@reduxjs/toolkit/";

 const defaultState = {
    header_menu: "home",
    modal_comand: false,
    modal_contacts: false,
    preloader: false,
    db_version: false,
    ln: false,
    isLoading: true,
    
 };

  const headerSlice = createSlice({
    name: "state",
    initialState: defaultState,
    reducers: {

      setMenu(state, action){
         state.header_menu = action.payload;
      },

      setModalComand(state, action){
        state.modal_comand = action.payload;
      },

      setModalContacts(state, action){
         state.modal_contacts = action.payload;
      },

      setPreloader(state, action){
         state.preloader = action.payload;
      },

      setDBVersion(state, action){
         state.db_version = action.payload;
      },

      setLanguage(state, action){
         state.ln = action.payload;
      },

      setIsLoading(state, action){
         state.isLoading = action.payload;
      },
    }
  });

export const { setMenu, setModalComand, setModalContacts, setPreloader, setDBVersion, setLanguage, setIsLoading } = headerSlice.actions;
export default headerSlice.reducer;
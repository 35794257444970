import React from 'react';
import s from './preloader.module.scss';
import heart from "../../images/Preloader.webp";

export const Preloader = ()=>{

    return (
        <div className={s.preloader__wrapper}><img className={s.preloader} src={heart} /></div>
    )
    
    
}
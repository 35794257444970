import React, { useEffect, useRef, useState } from 'react';
import s from './about_news.module.scss';
import { MyButton } from '../mybutton/mybutton';
import { useDispatch, useSelector } from 'react-redux';
import sprite from "../../images/svg_sprite.svg";
import { PreFooter } from '../prefooter/prefooter';
import { Footer } from '../footer/footer';
import { MyButtonRight } from '../mybutton-right/mybutton-right';
import { setPreloader } from '../../redux/stateSlice';
import { stopScroll } from '../../functions/stopScroll';
import gsap from 'gsap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { PostItem } from './postItem/postItem';
import { usePosts } from '../../utils/hooks/usePosts';
import { useTerms } from '../../utils/hooks/useTerms';
import { useMedia } from '../../utils/hooks/useMedia';

export const AboutNews = ()=>{

    const [searchParams, setSearchParams] = useSearchParams();
    const preloader = useSelector(store => store.state.preloader);
    const dispatch = useDispatch();
    let postId = parseInt(searchParams.get("id"));
    let nextId;
    let prevId;
    const navigate = useNavigate();
    const button_prev = useRef();
    const button_next = useRef();
    const about__buttons = useRef();
    let news_view;
    const post_data = {title_image: '', title: '', content: []};
    

    gsap.registerPlugin(ScrollTrigger);

    const getTerms = useTerms();
    const prev_btn = getTerms('prevbtn');
    const next_btn = getTerms('nextbtn');

    const getMedia = useMedia();

    const getPosts = usePosts();
    const posts = getPosts();
    let post = posts.filter(post => post.id === postId);

    if(post.length){
        post[0].template.forEach(tm => {
            if(tm.tm_name === 'news_title_image') post_data['title_image'] = getMedia(tm.value).link
            if(tm.tm_name === 'news_title') post_data['title'] = getTerms(tm.value)
        })
        post_data.content = post[0].content;

        posts.forEach((el, index) => {
            
            if(el.id === post[0].id){
                
            if(posts[index+1]) nextId = posts[index+1].id;
            if(posts[index-1]) prevId = posts[index-1].id;
            }
        })
    }
    
    
    useEffect(()=>{
        window.scrollTo(0, 0);
        window.addEventListener("wheel", stopScroll, { passive:false })
        let delay = 0;
        preloader? delay = 0 : delay = 4000;
        setTimeout(()=>{
            dispatch(setPreloader(true));
            window.removeEventListener("wheel", stopScroll)
        },delay);

        if(posts.length){
            if(postId === posts[0].id){
                button_prev.current.style.display = "none";
                about__buttons.current.style.justifyContent = "flex-end";
            } 
            if(postId === posts[posts.length - 1].id) button_next.current.style.display = "none";
            gsap.to('.about', {opacity: 1, duration: 1, delay: 0.7});
        }
       
    },[posts]);

    if(!posts.length) return;
    // console.log(posts);
    news_view = <PostItem post={post_data.content} />;
    
    
    function swapNext(){
        navigate(`/about-news?id=${nextId}`);
    }
    function swapPrev(){
        navigate(`/about-news?id=${prevId}`);
    }

    return(
        <>
        <section className={`${s.about} about`}>
            <header className={s.about__header}>
                <div className={`${s.about__buttons} about__buttons`} ref={about__buttons}>
                    <div onClick={swapPrev} value="prev" ref={button_prev} className={s.button__prev}>
                    <MyButton text={prev_btn} type="modal"/>
                    </div>
                    <div onClick={swapNext} value="next" ref={button_next} className={s.button__next}>
                    <MyButtonRight text={next_btn} type="modal"/>
                    </div>
                </div>
                <div className={s.about__title}><h1>{post_data.title}</h1></div>
                <div className={s.about__title_image} style={{
                    background: `url(${post_data.title_image}) no-repeat center/cover`,
                    height: post_data.title_image.length? '' : 0
                }}></div>
                {
                    post_data.title_image.length?(
                        <div className={s.header__line}>
                    <svg>
                        <use xlinkHref={`${sprite}#heart`}></use>
                    </svg>
                </div>
                    ) : null
                }
            </header>
            {news_view}
        </section>
        <PreFooter />
        <Footer />
        </>
    )
}

import s from './news.module.scss';
import { NewsItem } from './news_item/news_item';
import { MyButton } from '../../mybutton/mybutton';
import { Link } from 'react-router-dom';
import { useTerms } from '../../../utils/hooks/useTerms';
import { usePosts } from '../../../utils/hooks/usePosts';


export const News = ()=>{

    const getTerms = useTerms();
    const getPosts = usePosts();

    const posts = getPosts();
    const title = getTerms('nwst');
    const button = getTerms('newsbtn');
    
    if(!posts) return;
    let result_posts;

    
    const result = posts.map(post =>{
        const post_content = {id: post.id};
        post.template.forEach(tm => {
            if(tm.tm_type === "text") post_content['title'] = tm.content[0]?.pseudo;
            if(tm.tm_type === "file") post_content['title_image'] = tm.content[0]?.id;
        });
        for(let i in post.content){
            if(post.content[i].type === "text"){
                post_content['subtitle'] = post.content[i].value;
                break;
            }
        }
        return post_content;
    });
    result_posts = result;
    
    let news;
    window.innerWidth < 1000 && window.innerWidth > 768? news = result_posts.slice(0, 4) : news = result_posts.slice(0, 3);
    
    const news_view = news.map((el) => {
        return <NewsItem image={el.title_image} title={el.title} text={el.subtitle} id={el.id} key={el.id}/>
    })


    return(
        
        <section className={`${s.news} about`}>
            <h1 data-anim="opacity">{title}</h1>
            <div className={s.news__content}>
                {news_view}
            </div>
            <Link to="news/" className={s.news__button}>
                <MyButton text={button} type="modal" />
            </Link>
        </section>

    )
}
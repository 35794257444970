import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import Dexie from 'dexie';


export function useLink(){

    // Получаем текущай язык
    const ln = useSelector(store => store.state.ln);

    // Подучаем версию BD
    const version = useSelector(store => store.state.db_version);

    // Подключаемся к BD
    const db = new Dexie('store');
    db.version(version).stores({mfpt: "id"});

    // Получаем blocks из BD
    const blocks_query = useLiveQuery(() => db.mfpt.get(1));
    
        
    return function getLink(value){
        if(blocks_query){
            const blocks = blocks_query.blocks;
            // Получаем блок по термину
            const block = blocks.find(block => block.text === value);
            // Получаем термин текущего языка
            const terms = block.value.filter(el => el.lang_id == ln.id);
            // Возвращаем значение термина и ссылку
            return terms[0]? {text: terms[0].value, link: block.link} : {text: '', link: block.link};
                
            
                
        }else return false;
    }


} 
import React from 'react';
import { MyButton } from '../../mybutton/mybutton';
import s from './idea.module.scss';
import svg_sprite from '../../../images/svg_sprite.svg';
import { useDispatch } from 'react-redux';
import { setModalComand } from '../../../redux/stateSlice';
import { useTerms } from '../../../utils/hooks/useTerms';
import { useMedia } from '../../../utils/hooks/useMedia';

export const Idea = ()=>{

    const dispatch = useDispatch();
    function showModal(){
        dispatch( setModalComand(true) );
    }

    const getTerms = useTerms();
    const getMedia = useMedia();
    
    return(
        <section className={s.idea}>
            <div className={s.idea__content}>
                <h1 data-anim="opacity">{getTerms('tic')}</h1>
                <p data-anim="opacity">{getTerms('itb')}</p>
                <div className={s.button__wrapper} onClick={()=>{showModal()}}>
                    <MyButton text={getTerms('bti')} type="modal" />
                </div>
            </div>
            <div className={s.img__wrapper} style={{
                background: `url(${getMedia(641).link}) no-repeat center/cover`,
                }}></div>
            <div className={`${s.border__wrapper} idea__border_wrapper`} data-anim="line">
                <svg className={`${s.main__heart} animation_heart`}>
                    <use xlinkHref={`${svg_sprite}#heart`} ></use>
                </svg>
            </div>
        </section>
    )
}
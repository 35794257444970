import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useMedia } from '../../../utils/hooks/useMedia';
import { useTerms } from '../../../utils/hooks/useTerms';
import s from './block.module.scss';

export const Block = (props)=>{

    const getTerms = useTerms();
    const getMedia = useMedia();
    const img = useRef();

    const text_view = props.text.map((el, index) => {
        return <p key={index}>{getTerms(el)}</p>
    })

    useEffect(()=>{
        if(props.style === "left") img.current.style.cssText = `margin-right: 20px; float: left`;
        else img.current.style.cssText = `margin-left: 20px; float: right`;
    },[]);

    return(
        <div className={s.post__block}>
            <img className={s.post__lc} src={getMedia(props.media).link} alt={getMedia(props.media).alt} style={{float: props.style}} ref={img}></img>
            <div className={s.post__rc}>
                {text_view}
            </div>
        </div>
    )
}
import React from 'react';
import s from './mybutton-right.module.scss';
import img_heart from '../../images/svg_sprite.svg';

export const MyButtonRight = (props)=>{

    if(props.type === "modal"){
        return(
            <div className={s.button}>
                <span>{props.text}</span>
                <svg>
                    <use xlinkHref={`${img_heart}#heart`} ></use>
                </svg>
            </div>
        )
    }

}
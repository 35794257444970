import axios from "axios";

const address = process.env.REACT_APP_API_ADDRESS;

export async function getDBVersionApi(){
    try{
        return await axios.get(`${address}db/get/dbversion`);
    }catch(e){
        console.log(e);
    }
}

import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import Dexie from 'dexie';


export function useTerms(value){

    // Получаем текущай язык
    const ln = useSelector(store => store.state.ln);

    // Подучаем версию BD
    const version = useSelector(store => store.state.db_version);

    // Подключаемся к BD
    const db = new Dexie('store');
    db.version(version).stores({mfpt: "id"});

    // Получаем blocks из BD
    const terms_query = useLiveQuery(() => db.mfpt.get(4));
    
        
    return function getTerms(value){
        if(terms_query){

            const terms = terms_query.terms;
            // Получаем термин текущего языка
            const terms_result = terms.filter(el => el.lang_id == ln.id && el.pseudo === value);
            // Возвращаем значение термина
            return terms_result[0]? terms_result[0].value : '';
                
        }else return false;
    }
}

import React, { useEffect, useRef } from 'react';
import s from './home.module.scss';
import facebook_icon from "../../../images/icon_facebook.svg";
import viber_icon from "../../../images/icon_viber.svg";
import instagram_icon from "../../../images/icon_instagram.svg";
import { ReactComponent as LogoHeart } from '../../../images/logo_heart.svg';
import logo_mobile from "../../../images/logo_mobile.webp";
import svg_sprite from '../../../images/svg_sprite.svg';
import gsap from 'gsap';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContacts, setPreloader } from '../../../redux/stateSlice';
import { stopScroll } from '../../../functions/stopScroll';
import { useTerms } from '../../../utils/hooks/useTerms';
import { useMedia } from '../../../utils/hooks/useMedia';
import { useLink } from '../../../utils/hooks/useLink';

export const Home = ()=>{

    const main_logo = useRef();
    const video = useRef();
    const section = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const preloader = useSelector(store => store.state.preloader);

    const getTerms = useTerms();
    const getMedia = useMedia();
    const getLink = useLink();

  

    function scrollHandler(e){
        let bottom_position = section.current.getBoundingClientRect().top;
        let num = Math.abs(bottom_position) / 7;
        if(window.innerWidth < 550) num = Math.abs(bottom_position) / 5;
        main_logo.current.style.top=`${num}px`;
    }


    useEffect(()=>{
        window.scrollTo(0, 0);
        // показываем контакты если есть GET[modal]
        const modal = searchParams.get("modal");
        modal? dispatch(setModalContacts(true)) : dispatch(setModalContacts(false));

        //animation opacity
        window.addEventListener("wheel", stopScroll, { passive:false })
        let delay = 0;
        preloader? delay = 600 : delay = 4000;
        setTimeout(()=>{
            gsap.to('.main__social_anim', {opacity: 1, duration: 1, delay: 0.5});
            gsap.to('.main__text_anim', {opacity: 1, duration: 1, delay: 0.5});
            gsap.from('.main__animation_wrapper', {height: 0, duration: 1.5, delay: 0});
            gsap.to('.main__heart', {scale: 1, duration: 0.2, delay: 1.5});
            dispatch(setPreloader(true));
            window.removeEventListener("wheel", stopScroll);
            video.current.play();
        },delay);

        //animation logo
        window.addEventListener("scroll", scrollHandler);
        gsap.from('.main', {opacity: 0, duration: 1, delay: 0.7});
       
        return ()=>{ 
            window.removeEventListener("scroll", scrollHandler);
        }
    },[]);

    return(
        <section className={`${s.main} main`} ref={section}>
            <div className={s.main__social}>
                <div className={`${s.main__social_block} main__social_anim`} >
                    <a href={getLink("lt3").link}><img src={facebook_icon} alt="facebook" /></a>
                    <a href={getLink("lt2").link}><img src={viber_icon} alt="viber" /></a>
                    <a href={getLink("lt1").link}><img src={instagram_icon} alt="instagram" /></a>
                </div>
            </div>
            <div className={s.main__text}>
                <h3 className='main__text_anim'>{getTerms('mt1')}</h3>
                <div className={`${s.main__animation_wrapper} main__animation_wrapper`}>
                    <svg className={`${s.main__heart} main__heart`}>
                        <use xlinkHref={`${svg_sprite}#heart`} ></use>
                    </svg>
                </div> 
            </div>
            <img className={s.main__img1} src={getMedia(633).link} alt={getMedia(633).alt} />
            <div className={s.border__wrapper}><img className={s.main__img2} src={getMedia(632).link} alt={getMedia(632).alt} /></div>
            <div className={s.logo__wrapper} ref={main_logo}>
            <img className={s.logo__mobile} src={logo_mobile} alt="logoMobile" />
            <video ref={video} className={s.main__img3} src={getMedia(634).link} muted playsInline/>
                <div className={s.logo__bg}>
                    <LogoHeart className={s.logo__heart1}/>
                    <LogoHeart className={s.logo__heart2}/>
                    <LogoHeart className={s.logo__heart3}/>
                    <LogoHeart className={s.logo__heart4}/>
                </div>
            </div>
        </section>
    )
}
import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import Dexie from 'dexie';


export function usePosts(){

    const version = useSelector(store => store.state.db_version);

    const db = new Dexie('store');
    db.version(version).stores({
        mfpt: "id"
    });
    
    const posts_query = useLiveQuery(() => db.mfpt.get(3));

    return function getPosts(){
        if(posts_query) return posts_query.posts;
        else return [];
    }
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { setModalContacts } from '../../redux/stateSlice';
import { MyButton } from '../mybutton/mybutton';
import s from './prefooter.module.scss';
import { useTerms } from '../../utils/hooks/useTerms';

export const PreFooter = ()=>{

    const dispatch = useDispatch();
    const getTerms = useTerms();
    
    function openModal(){
        dispatch(setModalContacts(true));
    }

    return(
        <section className={s.contacts}>
            <h2>{getTerms('prft')}<br className={s.contacts__br}></br> {getTerms('prft2')}</h2>
            <div onClick={openModal}>
                <MyButton text={getTerms('prfl')} type="modal" />
            </div>
        </section>
    )
}
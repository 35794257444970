import React, { useEffect, useRef } from 'react';
import s from './contacts.module.scss';
import contacts_img from "../../images/command_bg.webp";
import contacts_p_img from "../../images/command_bg2.webp";
import qr from '../../images/qr.png';
import { MyButton } from '../mybutton/mybutton';
import svg_sprite from '../../images/svg_sprite.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContacts } from '../../redux/stateSlice';
import { useLink } from '../../utils/hooks/useLink';
import { useTerms } from '../../utils/hooks/useTerms';
import { useMedia } from '../../utils/hooks/useMedia';


export const Contacts = ()=>{

    const modalState = useSelector(store => store.state.modal_contacts);
    const modal = useRef();
    const dispatch = useDispatch();

    const getMedia = useMedia();
    const getTerms = useTerms();
    const getLink = useLink();

    useEffect(()=>{
        if(modalState){
            modal.current.classList.add('modal-active');
            document.querySelector('.container').style.filter = "blur(5px)";
        }
        else{
            modal.current.classList.remove('modal-active');
            document.querySelector('.container').style.filter = "blur(0px)";
        }
    },[modalState]);


    function closeModalContacts(){
        dispatch(setModalContacts(false));
    }

    return(
        <div className={s.contacts__wrapper} ref={modal}>
            <div className={s.contacts} style={{
                background: `url(${contacts_img})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center center'
            }}>
                <div className={s.contacts__content}>
                    <p>{getTerms('cmtt')}</p>
                    <img src={qr} alt="qr" />
                </div>
                <div className={s.button__wrapper} onClick={closeModalContacts}>
                    <MyButton text = {getTerms('cmtt')} type="modal"></MyButton>
                </div>
            </div>
            <div className={s.contacts__p} style={{
                background: `url(${contacts_p_img})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center center'
            }}>
                <h1>{getTerms('cmtt')}</h1>
                {/* <div className={s.info}>
                    <svg>
                        <use xlinkHref={`${svg_sprite}#icon_phone`}></use>
                    </svg>
                    <a href='tel:+447502255777'>+447502255777</a>
                </div> */}
                <div className={s.info}>
                    <svg>
                        <use xlinkHref={`${svg_sprite}#icon_mail`}></use>
                    </svg>
                    <a href={getLink('cmte').link}>{getLink('cmte').text}</a>
                </div>
                <div className={s.info}>
                    <svg>
                        <use xlinkHref={`${svg_sprite}#icon_location`}></use>
                    </svg>
                    <p>{getLink('cmtl').text}</p>
                </div>
                <h1>{getTerms('cmts')}</h1>
                <div className={s.social}>
                    <a target="_blank" href={getLink('lt1').link}><img src={getMedia(657).link} alt={getMedia(657).alt} /></a>
                    <a target="_blank" href={getLink('lt3').link}><img src={getMedia(656).link} alt={getMedia(656).alt} /></a>
                </div>
                <div className={s.button__wrapper} onClick={closeModalContacts}>
                    <MyButton text = {getTerms('closebtn')} type="modal"></MyButton>
                </div>
            </div>
            <div className={s.contacts__bg} onClick={closeModalContacts}></div>
        </div>
    )
}
import React, { useEffect, useRef } from 'react';
import s from './footer.module.scss';
import img_footer from '../../images/footer_logo.webp';
import svg_sprite from '../../images/svg_sprite.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setModalComand, setModalContacts } from '../../redux/stateSlice';
import { useTerms } from '../../utils/hooks/useTerms';
import { useLink } from '../../utils/hooks/useLink';
import { useMedia } from '../../utils/hooks/useMedia';



export const Footer = ()=>{
    const state = useSelector(store => store.state.header_menu);
    const menu_items = document.getElementsByClassName('footer__link');
    const dispatch = useDispatch();
    const menu_link = useRef();

    const getTerms = useTerms();
    const getLink = useLink();
    const getMedia = useMedia();

    // Set menu colors
    useEffect(()=>{
        for(let el of menu_items){
            el.classList.remove('menu_active');
        }
        if(state === "news") menu_link.current.classList.add("menu_active");
    },[state]);

    // Show modals
    function showModalComand(e){
        e.preventDefault();
        dispatch( setModalComand(true) );
    }
    function showModalContacts(e){
        e.preventDefault();
        dispatch( setModalContacts(true) );
    }

    return(
        <footer className={s.footer}>
            <div className={s.footer__logo}>
                <img src={getMedia(655).link} alt={getMedia(655).alt} />
            </div>
            <div className={s.footer__content}>
                <div className={s.footer__menu}>
                    {/* <a onClick={(e)=> showModalComand(e)} href="#" >{t("header-link3")}</a> */}
                    <Link className='footer__link news_link' to={getLink('hdln').link} ref={menu_link}>{getLink('hdln').text}</Link>
                    <a onClick={(e)=> showModalContacts(e)} className='footer__link contacts' >{getLink('hdlc').text}</a>
                </div>
                <div className={s.footer__copy}>
                    <p>{getTerms('coryrates')}</p>
                </div>
            </div>
            <div className={s.footer__social}>
                <a href={getLink('lt3').link}>
                    <svg className={s.main__heart}>
                        <use xlinkHref={`${svg_sprite}#icon_facebook`} ></use>
                    </svg>
                </a>
                <a href={getLink('lt1').link}>
                    <svg className={s.main__heart}>
                        <use xlinkHref={`${svg_sprite}#icon_instagram`} ></use>
                    </svg>
                </a>
                <a href={getLink('lt2').link}>
                    <svg className={s.main__heart}>
                        <use xlinkHref={`${svg_sprite}#icon_viber`} ></use>
                    </svg>
                </a>
            </div>
        </footer>
    )
}
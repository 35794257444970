import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMedia } from '../../../utils/hooks/useMedia';
import { useTerms } from '../../../utils/hooks/useTerms';
import s from './news_item.module.scss';

export const NewsItem = (props)=>{

    const link = `/about-news?id=${props.id}`;

    const getMedia = useMedia();
    const getTerms = useTerms();
    
    console.log("getMedia(props.image)?.link", getMedia(props.image)?.link);
    return(
        <div className={`${s.news__item} news__item`} data-anim="opacity">
            <div className={s.img__wrapper} style={{
                background: `url(${getMedia(props.image)?.link}) no-repeat center/cover`
                }}></div>
            <p className={s.news__title} ><Link to={link}>{getTerms(props.title)}</Link></p>
            <p className={s.news__text} >{getTerms(props.text)}</p>
            <div className={s.link__wrapper}>
                <Link to={link}>{getTerms('morebtn')}</Link>
            </div>
        </div>
    )
}
import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import Dexie from 'dexie';


export function useMedia(value){

    // Подучаем версию BD
    const version = useSelector(store => store.state.db_version);

    // Подключаемся к BD
    const db = new Dexie('store');
    db.version(version).stores({mfpt: "id"});

    // Получаем blocks из BD
    const media_query = useLiveQuery(() => db.mfpt.get(5));
        
    return function getMedia(value){
        if(media_query){
            const media = media_query.media;
            // Получаем блок по id media
            const media_result = media.find(el => el.id == value); 
            return {link: media_result?.link, alt: media_result?.alt};
                
        }else return false;
    }
}
import React from 'react';
import s from './product.module.scss';
import { ProductItem } from './product_item/product_item';
import { MyButton } from '../../mybutton/mybutton';
import { useTerms } from '../../../utils/hooks/useTerms';
import { useMedia } from '../../../utils/hooks/useMedia';
import { useLink } from '../../../utils/hooks/useLink';
import { useSelector } from 'react-redux';


export const Product = ()=>{

    const getTerms = useTerms();
    const getMedia = useMedia();
    const getLink = useLink();

    const img_approved_fr = getMedia(649).link;
    const img_approved = getMedia(650).link;
    const img_chuopi_fr = getMedia(651).link;
    const img_chuopi = getMedia(652).link;
    const img_cat = getMedia(653).link;
    const img_dogs = getMedia(654).link;


    let current_ln = useSelector(store => store.state.ln).key;

    const items = [
        {
            title: getTerms('pit1'),
            text: getTerms('pibt1'),
            image: getMedia(643)
        },
        {
            title: getTerms('pit2'),
            text: getTerms('pibt2'),
            image: getMedia(644)
        },
        {
            title: getTerms('pit3'),
            text: getTerms('pibt3'),
            image: getMedia(645)
        },
        {
            title: getTerms('pit4'),
            text: getTerms('pibt4'),
            image: getMedia(646)
        },
        {
            title: getTerms('pit5'),
            text: getTerms('pibt5'),
            image: getMedia(647)
        },
        {
            title: getTerms('pit6'),
            text: getTerms('pibt6'),
            image: getMedia(648)
        }
    ];

    const items_view = items.map((el, key) => {
        return <ProductItem title={el.title} text={el.text} image={el.image.link} key={key}/>
    });

    return(
        <section className={s.product}>
            <div className={s.product__header}>
                <h1>{getTerms('ptt')}</h1>
                <img className={s.product__approuve} src={current_ln === 'fr'? img_approved_fr:img_approved} alt="Approuve" data-anim="opacity" />
                <div className={s.product__pets}>
                    <img className={s.product__cats} src={img_cat} alt="Cat" data-anim="opacity"/>
                    <a href={getLink('chuopi').link}><img className={s.product__choupi} src={current_ln === 'fr'? img_chuopi_fr:img_chuopi} alt="Chuopi" /></a>
                    <img className={s.product__dog} src={img_dogs} alt="Dog" data-anim="opacity"/>
                </div>
            </div>
            <div className={s.product__content}>
                {items_view}
                <div className={s.product__button}>
                    <a href={getLink('chuopi').link}>
                        <MyButton text={getLink('chuopi').text} type="modal" />
                    </a>
                </div>
            </div>
        </section>
    )
}
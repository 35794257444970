import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMenu } from '../../redux/stateSlice';
import { Footer } from '../footer/footer';
import { PreFooter } from '../prefooter/prefooter';
import { Home } from './home/home';
import { Idea } from './idea/idea';
import s from './main.module.scss';
import { Mission } from './mission/mission';
import { News } from './news/news';
import { Product } from './product/product';
import { Quote } from './quote/quote';


export const Main = ()=>{
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setMenu('home'));
    },[]);

    return(
        <>
            <Home />
            <Mission />
            <Idea />
            <Quote />
            <Product />
            <News />
            <PreFooter />
            <Footer />
        </>
    )
}
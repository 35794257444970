import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from '../../../../utils/hooks/useMedia';
import { useTerms } from '../../../../utils/hooks/useTerms';
import s from './news_item.module.scss';

export const NewsItem = (props)=>{

    const getTerms = useTerms();
    const getMedia = useMedia();
    const link = `/about-news?id=${props.id}`;

    return(
        <div className={`${s.news__item} news__item`}>
            <div className={s.img__wrapper} style={{
                background: `url(${getMedia(props?.image)?.link}) no-repeat center/cover`,
                }}></div>
            <p className={s.news__title} data-anim="opacity"><Link to={link}>{getTerms(props?.title)}</Link></p>
            <p className={s.news__text} data-anim="opacity">{getTerms(props?.text)}</p>
            <div className={s.link__wrapper}>
                <Link to={link} data-anim="opacity">{getTerms('morebtn')}</Link>
            </div>
        </div>
    )
}
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useTerms} from '../../utils/hooks/useTerms';


export const Animation = (props)=>{
    const menuState = useSelector(store => store.state.header_menu);
    const getTerms = useTerms();
    const citate_text = getTerms('ctt');

    // Функция которая добавляет/удаляет классы для анимаций
    function scrollHandler(strState){
        const show_el = document.querySelectorAll("[data-anim]");
        for(let i=0; i<show_el.length; i++){
            let show_distance;
            let isShow = show_el[i].classList.contains("active-"+show_el[i].getAttribute("data-anim"));
            let el_height = show_el[i].offsetHeight;
            if(show_el[i].getAttribute("data-anim") === "line" ){
                show_distance = el_height / 1.5;
            } 
            else show_distance = el_height + 50;
            let el_rect = show_el[i].getBoundingClientRect().top - window.innerHeight;
                if(isShow){
                    if(el_rect > -50 || el_rect < window.innerHeight * -1 - el_height - 50){
                        // show_el[i].classList.remove("active-"+show_el[i].getAttribute("data-anim")); 
                        if(show_el[i].getAttribute("data-anim") === "write"){
                            // stopWriteText();
                        } 
                    } 
                }
                else{
                    if(el_rect + show_distance < 0 && el_rect > window.innerHeight * -1 - el_height + show_distance){
                        show_el[i].classList.add("active-"+show_el[i].getAttribute("data-anim"));
                        if(show_el[i].getAttribute("data-anim") === "write"){
                            writeText(citate_text);
                        } 
                    }
                }
        };
    }

    
    useEffect(()=>{
        setTimeout(()=>{
            scrollHandler();
        }, 700);
    },[]);
    useEffect(()=>{
        scrollHandler();
        window.addEventListener('scroll', scrollHandler);
        return ()=>{window.removeEventListener('scroll', scrollHandler)}
    },[menuState, citate_text]);

    useEffect(()=>{
        stopWriteText();
        scrollHandler();
    },[localStorage.getItem('lang')]);

    return props.children
}

function splitString(str) {
    const words = str.split(' ');
    const chunks = [];
    let currentChunk = '';
    let chunkSize = 42;
  
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if(chunks.length === 0) chunkSize = 35;
      else chunkSize = 42;
      if (currentChunk.length + word.length <= chunkSize) {
        currentChunk += `${word} `;
      } else {
        chunks.push(currentChunk.trim());
        currentChunk = `${word} `;
      }
    }
  
    if (currentChunk.length > 0) {
      chunks.push(currentChunk.trim());
    }
  
    return chunks;
  }

const timeouts = [];

function writeText(strState){
    // разделяем текст на отдельаные строки
    const text_quote = splitString(strState);
    // получаем контейнер
    const text_container = document.querySelector('.quote__text_container');
    // устанавливаем минимальную высоту контуйнера по кол-ву строк
    let line_height = window.getComputedStyle(text_container).lineHeight;
    let min_height = (line_height.slice(0, -2));
    min_height = parseInt(min_height);
    min_height = min_height + 3;
    text_container.style.minHeight = `${min_height * text_quote.length}px`; 
  
    function write(str){  
        const div = document.createElement('div');
        const p = document.createElement('p'); 
        const span = document.createElement('span');
        span.classList.add('quote__color_wrapper');
        div.appendChild(span);  
        div.appendChild(p); 
        text_container.appendChild(div);
        span.classList.add('active-bg');
 
        let promise = new Promise((resolve, reject) => {
            let timer = 30; 

            // Заходим в цикл с первой строкой массива
            for(let i=0; i<text_quote[str].length;i++){
                // Выводим по-символьно строку
                const timeout = setTimeout(()=>{  
                    p.innerHTML += text_quote[str][i];
                    // Если последний сивол в строке резолвим номер строки
                    if(text_quote[str].length - 1 === i) resolve(str);
                }, timer)
                // Увеличиваем интервал
                timer = timer + 30;
                // Пушим все таймауты в массив
                timeouts.push(timeout);
            }   
        });
        // Ждем вывода предыдущей строки
        promise.then((res)=> {
            str++; 
            // Если не последняя запускаем следующую строку
            if(str < text_quote.length) write(str)
            // Показываем имя в конце анимации
            else{
                if(document.querySelector('.quote__name_animation')){
                    setTimeout(()=>{document.querySelector('.quote__name_animation').style.opacity="1"}, 500);
                }
            } 
        });
    }
    write(0);

}

function stopWriteText(){
    const text_container = document.querySelector('.quote__text_container');
    if(text_container){
        // очищаем контейнер
        text_container.innerHTML = "";
        // скрываем имя
        document.querySelector('.quote__name_animation').style.opacity="0";
        // стопим все сеттаймауты
        timeouts.forEach(timeout => {
        clearTimeout(timeout);
        });
        // убираем class для повторного запуска
        document.querySelector('.quote__text').classList.remove("active-write");
    }
}